<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista parametrów</h3>
        </header>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['name']">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono danych
            </template>
            <template #loading>
                Ładwanie paraetrów. Proszę czekać
            </template>
            <Column field="description" header="Parametr" :sortable="true"></Column>
            <Column field="value" header="Wartość" :sortable="true"></Column>
            <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                <template #body="slotProps">
                    <Button type="button" label="Edytuj"
                            v-on:click="editParameter(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import RolesService from "../../services/parameters.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        inject: ['global'],
        name: "ParametersList",
        data() {
            return {
                content: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            editParameter: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/parametry/edytuj/${id}`});
            },
            getList: function () {
                RolesService.getAllParameters().then(
                    (response) => {
                        this.loading = false;
                        this.content = response.data;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
        },
        mounted() {
            this.getList();
        },
    };
</script>
